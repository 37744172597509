<template>
  <NavBar></NavBar>
  <h2>Success, your order has been confirmed</h2>

</template>

<script>
import NavBar from "../components/NavBar";

export default {
  name: "Success",
  components: {NavBar}
}
</script>

<style scoped>

</style>